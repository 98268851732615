import React from 'react'
import {Link} from 'gatsby'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ]
};


const Banner = () => {

     const [display, setDisplay] = React.useState(false);

     React.useEffect(() => {
         setDisplay(true);
     }, [])
 

return (
    <React.Fragment>



{display ? <OwlCarousel 
    className="home-slides owl-carousel owl-theme"
    {...options}
> 

    <div className="main-banner-item item-bgB1">
   
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="banner-item-content">
                   
                        <span className="sub-title">ISBT 128 Label Printing System</span>
                        <h1>Print the entire spectrum of blood bank labels</h1>
                        <p>Easily print the full range of ISBT 128 labels.</p>
                        
                        <div className="btn-box">
                            <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                                <span></span>
                            </Link>   
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</OwlCarousel> : ''}
</React.Fragment>
)
}

export default Banner