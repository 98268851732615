import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import icon1 from '../../assets/images/pic1.jpeg'
import icon2 from '../../assets/images/pic2.jpeg'
import icon3 from '../../assets/images/pic3.jpeg'
import icon4 from '../../assets/images/pic4.jpeg'



const NewLabel = () => {
    return (
        <section className="services-area ptb-100 bg-e3fbff">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="image" /> 
                        Our Services
                    </span>
                    <h2>The ultimate solution to your blood products compliance labelling needs</h2>
                    <p>Have quick access to the main product labels you need to be printed and maintain a log of all printed labels.</p>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={icon1} alt="image" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Blood group label 2x2
                                </Link>
                            </h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna doloresssit amet aliqua.</p> */}
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={icon2} alt="image" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                     Full face label 4x4
                                </Link>
                            </h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna doloresssit amet aliqua.</p> */}

                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={icon3} alt="image" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Product and expiry label 4x2
                                </Link>
                            </h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna doloresssit amet aliqua.</p> */}
                         
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={icon4} alt="image" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Donation Identification label 1piece 6pieces 8 pieces 10 pieces
                                </Link>
                            </h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna doloresssit amet aliqua.</p> */}
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewLabel