import React from 'react'
import projectservice from '../../assets/images/projects/ISBT/Display.jpg'


const Details = () => {
    return (
        <section className="services-details-area ptb-100" style={{backgroundColor:'white'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                       
                        <div className="services-details-desc">
                            <h1> Features </h1>
                            
                                <div className="content">          
                                     <h3>Print All forms of blood bank labels including </h3>
                                        <ul>
                                            <li>Complete full face blood group label (4 Quadrant)</li>
                                            <li>Assign phlebotomist for collection.</li>
                                            <li>Product and Expiry label (2 Quadrant)</li>
                                            <li>Blood Group label (Single Quadrant)</li>
                                            <li>Donor Identification Number</li>
                                            <li>Have Quick access to your ICCBBA product list to be printed</li>
                                            <li>Maintain your printed label history for Reprint </li>
                                            <li>Restricted rights to access each functionality</li>
                                            <li>Reports including the record of printed label details in the selected duration</li>
                                            <li>Additional Features Including </li>
                                            <li>Pooled Product preparation and label Printing </li>
                                            <li>⦁	External unit enrollment and label printing along with its report including the unit details enrolled in the specific duration </li>
                                        </ul>
                                </div>                              
                        </div>
                    </div>    
                                
                    <div className="col-lg-6 col-md-12">
                    <img src={projectservice} alt="side img" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Details