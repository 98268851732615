import React from 'react'
import { Link } from 'gatsby'
import blogImg4 from '../../assets/images/blog/blog-img4.jpg'
import blogImg5 from '../../assets/images/blog/blog-img5.jpg'
import blogImg6 from '../../assets/images/blog/blog-img6.jpg'
import user1 from '../../assets/images/pic1.jpeg'
import user2 from '../../assets/images/pic2.jpeg'
import user3 from '../../assets/images/pic3.jpeg'
import user4 from '../../assets/images/pic4.jpeg'
import shape1 from '../../assets/images/shape/vector-shape1.png'
import shape2 from '../../assets/images/shape/vector-shape2.png'

const Lebels = () => {
    return (
        <section className="blog-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    {/* <span className="sub-title">Our Blog</span> */}
                    <h2>The ultimate solution to your blood products compliance labelling needs</h2>
                    <p>Have quick access to the main product labels you need to be printed and maintain a log of all printed labels.</p>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                               
                                    <img src={user1} alt="partner" />
                               
                            </div>

                            <div className="post-content">
                                {/* <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                            <img src={user1} className="rounded-circle" alt="partner" />
                                            <span>Alex Morgan</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i> April 30, 2020
                                    </li>
                                </ul> */}
                                <h3>
                                 Blood group label 2x2   
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="single-blog-post bg-fffbf5" >
                            <div className="post-image">
                                <Link to="/blog-details">
                                    <img src={user2} alt="partner" />
                                </Link>
                            </div>

                            <div className="post-content">
                                {/* <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                            <img src={user2} className="rounded-circle" alt="partner" />
                                            <span>Sarah Taylor</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i> April 28, 2020
                                    </li>
                                </ul> */}
                                <h3>
                                Full face label 4x4
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 offset-lg-0 offset-md-3">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to="/blog-details">
                                    <img src={user3} alt="partner" />
                                </Link>
                            </div>

                            <div className="post-content">
                                {/* <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                            <img src={user3} className="rounded-circle" alt="partner" />
                                            <span>David Warner</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i> April 29, 2020
                                    </li>
                                </ul> */}
                                <h3>
                                    Product and expiry label 4x2
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 offset-lg-0 offset-md-3">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to="/blog-details">
                                    <img src={user4} alt="partner" />
                                </Link>
                            </div>

                            <div className="post-content" >
                                {/* <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        
                                    </li>
                                    <li>
                                       
                                    </li>
                                </ul> */}
                                <h3>
                                     Donation Identification label
                                        1piece
                                        6pieces
                                        8 pieces 
                                        10 pieces
                                </h3>
                            </div>
                        </div>
                    </div>

                  
                </div>
            </div>

            <div className="vector-shape1">
                <img src={shape1} alt="partner" />
            </div>
            <div className="vector-shape2">
                <img src={shape2} alt="partner" />
            </div>
        </section>
    )
}

export default Lebels